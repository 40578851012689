














import { Component, Ref, Vue } from 'vue-property-decorator';
import { apireq } from '@/utils/apiRequest';
import PostMetadata from '@/components/PostMetadata.vue';
import MyButton from '@/components/MyButton.vue';
import GetPosts from '@/components/GetPosts.vue';

@Component({
  components: {
    PostMetadata,
    MyButton,
    GetPosts,
  },
})
export default class Blog extends Vue {
  boards: myBoard[] = [];

  active = 0;

  created() {
    apireq('GET', '/board')
      .then((res) => {
        const all = {
          ID: 0,
          URL: 'all',
          Name: '전체',
        };
        this.boards = [all, ...res.data.data];
      });
  }
}
